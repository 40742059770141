import React from 'react';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

export default () => (
  <ArticleLayout category="document" title="项目生命周期" subtitle="一个开发项目的实施流程">
    <KeyPoint
      index="1."
      title="需求沟通"
      content="与客户明确需求，并确认需求的可行性，拟定方案：需求分析会议；"
    />
    <KeyPoint
      index="2."
      title="签约"
      content="根据方案约定费用，制定项目标准和验收机制，制定时间表，签订合同；"
    />
    <KeyPoint
      index="3."
      title="领域建模"
      content="架构师与客户的行业专家就业务进行深入交流，制定长期规划：形成通用语言与词汇表、业务中的事件风暴、关键指标梳理、规划项目架构；"
    />
    <KeyPoint
      index="4."
      title="设计"
      content="设计师了解用户的品牌理念，制定相应的综合设计方案：产品设计、交互设计与图片设计；"
    />
    <KeyPoint
      index="5."
      title="项目管理"
      content="根据 User Story 分解业务模块，制定 Milestone 计划；"
    />
    <KeyPoint
      index="6."
      title="技术研发"
      content="实施具体的技术将产品落地：环境搭建（开发环境、测试环境、预生产环境）、监控平台的搭建、代码编写、DevOps（通过 CI/CD 进行单元测试与集成测试）；"
    />
    <KeyPoint
      index="7."
      title="测试"
      content="经过单元测试、集成测试后部署到预生产环境，与客户一起检查，测试包括但不限于：功能测试、端到端测试、并发与压力测试、安全清单检查、容灾能力检查；"
    />
    <KeyPoint
      index="8."
      title="文档撰写"
      content="包括但不限于： WiKi、代码变更日志、周报、需求与错误追踪、部署与运维指南、产品文档、运营文档；"
    />
    <KeyPoint
      index="9."
      title="正式上线"
      content="客户确认后，产品正式上线，包括但不限于：规划中的系统维护、数据分析。"
    />
    <KeyPoint
      index="∞"
      title="版本迭代"
      content="我们始终愿意与客户建立长期的合作伙伴关系。为了不断适应合作伙伴的飞速成长，我们也会适时为之调整项目的结构与细节设计，在版本更替中不断完善产品。"
    />
  </ArticleLayout>
);
